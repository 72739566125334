import React, { ComponentProps } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const queryClient = new QueryClient()

type QueryProviderProps = Omit<
  ComponentProps<typeof QueryClientProvider>,
  "client"
>

function QueryProvider(props: QueryProviderProps) {
  return <QueryClientProvider client={queryClient} {...props} />
}

export default QueryProvider
