/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React from "react"
import "./src/styles/global.css"
import QueryProvider from "./src/lib/queryProvider"

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location
  const scrollToTopRoutes = ["/privacy-policy", "/terms-of-use"]

  if (scrollToTopRoutes.includes(pathname)) {
    window.scrollTo(0, 0, { behavior: "smooth" })
  }

  return false
}

export const wrapRootElement = ({ element }) => (
  <QueryProvider>{element}</QueryProvider>
)
