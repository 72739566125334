module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3_ncmx6l6hrbuslgvbrrqkj2bu74/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Acre","short_name":"Acre","start_url":"/","background_color":"#F3E5C1","theme_color":"#231F20","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c47530d9da02e9593fd213152538e7f5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-_bxdl75uocqyjnoecndadejsjwa/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"maintainCase":false,"removeAccents":true,"elements":["h2"],"icon":" \n                <svg \n                  xmlns=\"http://www.w3.org/2000/svg\" \n                  width=\"24\" \n                  height=\"24\" \n                  viewBox=\"0 0 24 24\" \n                  fill=\"none\" \n                  style=\"transform: translateY(4px);\"\n                  stroke=\"currentColor\" \n                  stroke-width=\"2\" \n                  stroke-linecap=\"round\" \n                  stroke-linejoin=\"round\" \n                >\n                  <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"/>\n                  <path d=\"M9 15l6 -6\" /><path d=\"M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464\" />\n                  <path d=\"M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463\" />\n                </svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__e2vgnht6eit4byt2gc4jpgdvnm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
